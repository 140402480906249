// Global packages or components
import { useLoaderData, redirect } from '@remix-run/react'
import { getPage } from '~/sanity'
import { getMeta } from '~/utils'
import { Blocks } from '~/components'

// Type declarations
import type {
  MetaFunction,
  LoaderFunction,
  LoaderFunctionArgs,
} from '@remix-run/node'

// Route loader
export const loader: LoaderFunction = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const env = process.env
  const url = new URL(request.url)
  const slug = params['*']

  // Issue with URL
  if (!slug) {
    throw new Response('Not Found', {
      status: 404,
    })
  }

  // Sanity fetch
  const data = await getPage({
    dataset: env?.ENVIRONMENT,
    projectId: env?.SANITY_PROJECT,
    slug,
  })

  // Page not found in Sanity
  if (!data) {
    throw new Response('Not Found', {
      status: 404,
    })
  }

  // SEO data
  const seo = data?.seo

  // Custom page level redirect
  if (seo?.redirectPage && seo?.redirect?.type) {
    return redirect(
      seo?.redirect?.page?.slug?.current
        ? '/' + seo.redirect.page.slug.current
        : '/',
      Number(seo.redirect.type)
    )
  }

  // Return data
  return Response.json({
    seo: seo,
    blocks: data?.flexibleContent,
    path: url?.pathname,
  })
}

// Route meta
export const meta: MetaFunction = ({ matches, data }) => {
  return getMeta({
    matches,
    data,
  })
}

// Route view
export default function Index() {
  const { blocks } = useLoaderData<typeof loader>()

  return <main>{blocks && <Blocks blocks={blocks} />}</main>
}
